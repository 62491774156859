/* Template Name: Qexal - Responsive Bootstrap 5 Landing Page Template
   Author: Themesbrand
   Version: 2.1.0
   Created: Jan 2019
   File Description: Main Css file of the template
*/

/**********************
01.General
02.Icons
03.Menu
04.Components
05.Helper
06.Loader
07.Hero
08.Services
09.Features
10.Pricing
11.Team
12.Contact
13.Footer
***********************/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@import "variables";
@import "general";
@import "icons";
@import "menu";
@import "components";
@import "helper";
@import "loader";
@import "hero";
@import "service";
@import "features";
@import "pricing";
@import "team";
@import "contact";
@import "footer";

* {
  font-family: 'Montserrat',sans-serif !important;
}
